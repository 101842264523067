import about1 from "../../assets/images/service-1.gif";
import about2 from "../../assets/images/service-2.gif";
import about3 from "../../assets/images/service-3.gif";

import services1 from "../../assets/images/about-img-1.svg";
import services2 from "../../assets/images/about-img-2.svg";
import services3 from "../../assets/images/about-img-3.svg";
import services4 from "../../assets/images/about-img-4.svg";

import Navs from "./dashboard/Navs";
import AllUsers from "./dashboard/AllUsers";

export const about = [
  {
    id: 1,
    image: about1,
    title: "Innovation",
    description:
      "At our Programming Club, innovation is our driving force. We encourage creative thinking, problem-solving, and pushing the boundaries of what's possible in the world of coding.",
  },
  {
    id: 2,
    image: about2,
    title: "Collaboration",
    description:
      "Collaboration is at the heart of our Programming Club. Join a diverse community of tech enthusiasts, where teamwork and knowledge sharing lead to extraordinary achievements.",
  },
  {
    id: 3,
    image: about3,
    title: "Learning",
    description:
      "In the spirit of continuous learning, our Programming Club is your gateway to knowledge. Explore new technologies, gain expertise, and embark on a journey of growth with us",
  },
];

export const services = [
  {
    id: 1,
    image: services1,
    description: "Enhancing awareness and interest in programming.",
  },
  {
    id: 2,
    image: services2,
    description:
      "Enhancing collaboration with institutions and tech companies.",
  },
  {
    id: 3,
    image: services3,
    description:
      "Encouraging innovation and participation in programming projects.",
  },
  {
    id: 4,
    image: services4,
    description: "Providing technical and academic support.",
  },
];

export const codeLines = [
  { text: "function ProgrammingClub() {", class: "color-3" },
  { text: "  let i = 0;", class: "color-7" },
  { text: '  let message = "";', class: "color-7" },
  { text: "  while (i < 10) {", class: "color-6" },
  {
    text: '    message += "Programming Club SUCCESSED!!!! " + "\\n";',
    class: "color-1",
  },
  { text: "    i++;", class: "color-1" },
  { text: "  }", class: "color-3" },
  { text: "  return message;", class: "color-7" },
  { text: "}", class: "color-7" },
];

// for dashboard nav
export const conditions = [
  {
    pathname: "/Dashboard",
    component: (props) => <Navs {...props} />,
  },
  {
    pathname: "/Dashboard/Users",
    component: () => <AllUsers />,
  },
];
