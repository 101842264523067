import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Editor from "./Editor";
import swal from "sweetalert";
function FullQuestion() {
  const { questionId } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [question, setQuestion] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(null);
  const [answerContent, setAnswerContent] = useState("");
  const [canSubmitAnswer, setCanSubmitAnswer] = useState(true);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);

  // check if the user can submit an answer
  const checkCanSubmitAnswer = async () => {
    const username = localStorage.getItem("username");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/canSubmitAnswer/${username}/${questionId}`
      );
      const data = await response.json();
      setCanSubmitAnswer(data.canSubmitAnswer);
    } catch (error) {
      console.error("Error checking if user can submit an answer:", error);
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getQuestion/${questionId}`)
      .then((response) => response.json())
      .then((data) => {
        setQuestion(data);
      })
      .catch((error) => {
        console.error("Error fetching full question:", error);
      });
  }, [questionId]);

  useEffect(() => {
    if (queryParams.has("questionNumber")) {
      const number = queryParams.get("questionNumber");
      setQuestionNumber(number);
    }
  }, [queryParams]);

  // Check if the user can submit a question and answer when the component mounts
  useEffect(() => {
    checkCanSubmitAnswer();
  }, []);

  const submitAnswer = async () => {
    try {
      const username = localStorage.getItem("username");
      const content = answerContent;
  
      if (!content.trim()) {
        console.error("Answer content is empty");
        return;
      }
   
      const confirmSubmit = await swal({
        title: "Are you sure?",
        text: "Do you want to submit your answer?",
        icon: "warning",
        buttons: ["No, cancel", "Yes, submit"],
      });
  
      if (!confirmSubmit) { 
        return;
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submitAnswer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            questionId,
            content: answerContent,
          }),
        }
      );
  
      if (response.ok) {
        swal("Success!", "Your answer has been submitted", "success");
        setAnswerContent("");
        setCanSubmitAnswer(false);
      } else {
        swal("Error!", "Failed to submit your answer.", "error");
      }
    } catch (error) {
      swal("Error!", "Failed to submit your answer.", "error");
    }
  };
  

  return (
    <div className="full-question" style={{ paddingTop: 120 }}>
      {question && (
        <div className="full-question-card card">
          <div className="question-title cf w-100 valign-child">
            <div className="fl">
              <h2>Question {questionNumber}</h2>
            </div>
          </div>
          <div className="full-question-content">
            <p dangerouslySetInnerHTML={{ __html: question.content }} />
          </div>

          <Editor
            answerContent={answerContent}
            setAnswerContent={setAnswerContent}
          />

          {question.isActive ? (
            canSubmitAnswer ? (
              <button className="approveBtn" onClick={submitAnswer}>
                Submit Your Answer
              </button>
            ) : (
              <p>You have already submitted an answer for this question.</p>
            )
          ) : (
            <p>This question is currently retired and cannot be answered.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default FullQuestion;
