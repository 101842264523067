import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import "../css/Question.css";
import QuestionList from "./QuestionList";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";
function Editor({ placeholder, isDark }) {
  const [editorHtml, setEditorHtml] = useState("");
  const [questions, setQuestions] = useState([]);

  const token = localStorage.getItem("token");
  let userRole = "";
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.userRole;
    } catch (error) {
      console.error("Error decoding token: ", error);
    }
  }

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = () => {
    fetch(`${process.env.REACT_APP_API_URL}/getQuestions`)
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
  };

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_API_URL}/askQuestions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ content: editorHtml }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Question submitted successfully:", data);
        fetchQuestions();

        swal("Success!", data.message, "success");
      })
      .catch((error) => {
        console.error("Error submitting question:", error);
      });
  };

  const iconClass = isDark ? "white-icon" : "black-icon";
  const boxClass = isDark ? "white-box" : "black-box";

  return (
    <div
      className={`container ${isDark ? "dark-mode" : ""}`}
      style={{ paddingTop: 150 }}
    >
      {userRole === "admin" && (
        <div className="text-editors">
          <input name="first input" className={iconClass} />
          <ReactQuill
            onChange={handleChange}
            value={editorHtml}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={placeholder}
            className={boxClass}
          />
          <button className="approveBtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}

      <QuestionList questions={questions} />
    </div>
  );
}

Editor.propTypes = {
  placeholder: PropTypes.string,
  isDark: PropTypes.bool,
};

Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default Editor;
