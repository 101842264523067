import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children, requiredRole }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const token = localStorage.getItem("token");  
 
  const [decodedToken, setDecodedToken] = useState(null);
 
  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Error decoding token: ", error);
      }
    }
  }, [token]);
 
  if (!isLoggedIn) {
    return <Navigate to="/Admin/login" />;
  }
 
  if (requiredRole && decodedToken && decodedToken.userRole !== requiredRole) {
    return <Navigate to="/Dashboard" />;
  }

  return children;
};

export default ProtectedRoute;
