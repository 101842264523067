import React, { useState, useEffect } from "react";
import { useTable, useSortBy, useFilters } from "react-table";

function RequestedUsers({ isDark }) {
  const [Users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            throw new Error("You are not authorized to view this data.");
          } else {
            throw new Error("Something went wrong! Status: " + response.status);
          }
        }

        const usersData = await response.json();

        const formattedUsers = usersData.map((user) => ({
          id: user._id,
          username: user.name,
          utasID: user.utasId,
          email: user.email,
          approved: user.approved,
          registeredDate: user.registeredDate,
        }));

        setUsers(formattedUsers);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleApprove = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/approve-user/${userId}`, {
        method: 'PATCH',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Error approving user');
      }
   
      setUsers(Users.map(user => user.id === userId ? { ...user, approved: true } : user));
    } catch (error) {
      console.error('Error:', error); 
    }
  };
  

  const userColumns = React.useMemo(
    () => [
      { Header: "Name", accessor: "username", sortType: "basic" },
      { Header: "utasID", accessor: "utasID" },
      { Header: "Email", accessor: "email" },
      {
        Header: "Approval Status",
        accessor: "approved",
        Cell: ({ value }) => (
          <span className={value ? "approved" : "pending"}>
            {value ? "Approved" : "Pending"}
          </span>
        ),
      },
      {
        Header: "Approve",
        id: "approve",
        Cell: ({ row }) => (
          <button
            type="button"
            className="approveBtn"
            onClick={() => handleApprove(row.original.id)}
            disabled={row.original.approved}
          >
            Approve
          </button>
        ),
      },
    ],
    []
  );

  const userTableInstance = useTable(
    { columns: userColumns, data: Users },
    useSortBy
  );

  const renderTable = (tableInstance) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={`table ${isDark ? "dark-mode" : ""}`}>
      <h1 style={{ paddingTop: "20px" }}>All Users</h1>
      {renderTable(userTableInstance)}
    </div>
  );
}

export default RequestedUsers;
