import React from "react";
import { about } from "./assets.js";

function About() {
  return (
    <section className="section service" id="about">
      <div className="container">
        <h2 className="h2 section-title">Programming Club</h2>

        <p className="section-text">
          where innovation meets code! Join us on a journey of learning,
          collaboration, and endless possibilities in the world of programming.
        </p>

        <ul className="service-list">
          {about.map((about) => (
            <li key={about.id}>
              <div className="service-card">
                <figure className="card-banner">
                  <img
                    src={about.image}
                    width="728"
                    height="344"
                    loading="lazy"
                    alt={about.title}
                    className="w-100"
                  />
                </figure>

                <div className="card-content">
                  <h3 className="h3">
                    <a href="#" className="card-title">
                      {about.title}
                    </a>
                  </h3>

                  <p className="card-text">{about.description}</p>

                  <a href="#" className="btn-link">
                    <span className="span">Learn More</span>
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default About;
