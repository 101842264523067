import React, { useState, useEffect } from "react";
import { useTable, useSortBy, useFilters } from "react-table";

function AllUsers({ isDark }) {
  const [Admins, setAdmins] = useState([]);
  const [Users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Retrieving Admins
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admins`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            throw new Error("You are not authorized to view this data.");
          } else {
            throw new Error("Something went wrong! Status: " + response.status);
          }
        }

        const adminsData = await response.json();

        const formattedAdmins = adminsData.map((admin) => ({
          id: admin._id,
          username: admin.name,
          utasID: admin.utasId,
          email: admin.email,
        }));

        setAdmins(formattedAdmins);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAdmins();
  }, []);

  // Retrieving Users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            throw new Error("You are not authorized to view this data.");
          } else {
            throw new Error("Something went wrong! Status: " + response.status);
          }
        }

        const usersData = await response.json();

        const formattedUsers = usersData.map((user) => ({
          id: user._id,
          username: user.name,
          utasID: user.utasId,
          email: user.email,
          approved: user.approved,
          registeredDate: user.registeredDate,
        }));

        setUsers(formattedUsers);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const adminColumns = React.useMemo(
    () => [
      { Header: "Name", accessor: "username", sortType: "basic" },
      { Header: "utasID", accessor: "utasID" },
      { Header: "Email", accessor: "email" },
    ],
    []
  );

  const userColumns = React.useMemo(
    () => [
      { Header: "Name", accessor: "username", sortType: "basic" },
      { Header: "utasID", accessor: "utasID" },
      { Header: "Email", accessor: "email" },
      {
        Header: "Approval Status",
        accessor: "approved",
        Cell: ({ value }) => (
          <span className={value ? 'approved' : 'pending'}>
            {value ? 'Approved' : 'Pending'}
          </span>
        ),
      },
      { Header: "Registered Date", accessor: "registeredDate" },
    ],
    []
  );

  const adminTableInstance = useTable(
    { columns: adminColumns, data: Admins },
    useSortBy
  );
  const userTableInstance = useTable(
    { columns: userColumns, data: Users },
    useSortBy
  );

  const renderTable = (tableInstance) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={`table ${isDark ? "dark-mode" : ""}`}>
      <h1>All Admins</h1>
      {renderTable(adminTableInstance)}
      <h1 style={{ paddingTop: "20px" }}>All Users</h1>
      {renderTable(userTableInstance)}
    </div>
  );
}

export default AllUsers;
