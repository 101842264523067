import React, { useState, useEffect } from "react";

function Popup() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowPopup(true), 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className="popup-bg">
      <div className="popup-msg">
        <div className="close-x" onClick={handleClose}>
          close
        </div>
        <h2>Would you Like to Join Our Club!</h2>
        <p>
          There's definitely some important text here compelling you to push the
          button. Go on; you know you want to do it. Think of the children.
        </p>
        <a
          className="give-button"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdPqTMa-yi-peWD_btoOkHFGCFzJye6KciBxarbNYCzrKyWVw/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          Take Action
        </a>
        <button className="give-button close-button" onClick={handleClose}>
          Not Now
        </button>
      </div>
    </div>
  );
}

export default Popup;
