import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Navs from "./dashboard/Navs";

const HeaderWrapper = [
  {
    condition: (location) => location.pathname === "/Dashboard",
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname === "/Questions",
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname === "/Dashboard/Users",
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname === "/Dashboard/RequestedUsers",
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname.startsWith("/question/"),
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname.startsWith("/Admin/Answers"),
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname === "/Admin/Answers",
    component: <Navs />,
  },
  {
    condition: (location) => location.pathname === "/Admin/NewAnswers",
    component: <Navs />,
  },

  {
    condition: () => true,
    component: <Header />,
  },
];

function HeaderWrapperContainer({ isDark, toggleDark }) {
  const location = useLocation();

  const matchedCondition = HeaderWrapper.find((item) =>
    item.condition(location)
  );
  const componentToRender = matchedCondition
    ? React.cloneElement(matchedCondition.component, { isDark, toggleDark })
    : null;

  return <>{componentToRender}</>;
}

export default HeaderWrapperContainer;
