import React, { useState } from "react";
import { codeLines } from "./assets";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Popup from "./Popup";

function Home({ isDark }) {
  const [message, setMessage] = useState("");

  const programmingClub = () => {
    let i = 0;
    let message = "";
    while (i < 10) {
      message += "Programming Club SUCCESSED!!!! " + "\n";
      i++;
    }
    return message;
  };

  const handleRunClick = () => {
    const newMessage = programmingClub();
    setMessage(newMessage);
  };

  const darkModeClass = isDark ? "dark-mode" : "";

  return (
    <main className={darkModeClass}>
      <section
        className="section app"
        id="home"
        style={{ paddingTop: "250px" }}
      >
        <div className="container">
          <div className="app-content">
            <h2 className="h2 section-title">
              <div className="moving">
                <span className="move">
                  Hello Programmer <span className="wave">👋</span> ! <br />
                  Welcome to Our <br />
                  Programming Club Page
                </span>
              </div>
            </h2>
            <div className="hero-text">
              <p className="section-text">
                There is a &nbsp;
                <span className="wave" style={{ cursor: "pointer" }}>
                  Hidden Button
                </span>
                <br />
                on the IDE to Run the Code shown, try to find it and solve it,
                if you solve it,
                <br />
                take a picture and send it to us
                <a href="https://www.instagram.com/utas_progclub/">here 👈</a>
                <span className="phone">
                  The IDE is Available only in Desktop VIEW
                </span>
              </p>
            </div>
          </div>

          <figure className="app-banner">
            <div className="window">
              <header className="headclass">
                <ul className="cm-icons">
                  <li title="close"></li>
                  <li title="Restore Down"></li>
                  <li title="Minimize"></li>
                </ul>
              </header>
              <div className="editor" contentEditable="false">
                <pre>
                  <code id="codeContent">
                    {codeLines.map((line, index) => (
                      <div key={index} className={line.class}>
                        {line.text}
                      </div>
                    ))}
                  </code>
                  <code id="codeContent">
                    <span className="color-3">{message}</span>
                  </code>
                </pre>
                <button
                  id="showPopupButton"
                  onClick={handleRunClick}
                  className="btn"
                  style={{ display: "none" }}
                >
                  Run
                </button>
              </div>
            </div>
          </figure>
        </div>
      </section>
      <About isDark={isDark} />
      <Services isDark={isDark} />
      <Contact isDark={isDark} />
      <Popup />
    </main>
  );
}

export default Home;
