import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useParams } from "react-router-dom";

function Answers() {
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
  const { id } = useParams();

  const token = localStorage.getItem("token");
  let userRole = "";
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.userRole;
    } catch (error) {
      console.error("Error decoding token: ", error);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_API_URL}/QuestionAnswers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setQuestionsWithAnswers(data);
      })
      .catch((error) => {
        console.error("Error fetching questions with answers:", error);
      });
  }, [token]);

  return (
    <div className="container" style={{ paddingTop: "150px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-5">
            <ul className="question-list">
              {questionsWithAnswers.map((question) => (
                <li key={question._id}>
                  <div className="question-card">
                    <div className="card-banner"></div>
                    <div className="card-content">
                      <h3 className="h3">
                        <div href="#" className="card-title ccards">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: question.content,
                            }}
                          />
                        </div>
                      </h3>
                      <ul>
                        <p>Answered By: {question.username}</p>
                        <button>Grade</button>
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Answers;

// import React, { useState, useEffect } from "react";
// import { jwtDecode } from "jwt-decode";
// function Answers() {
//   const [answers, setAnswers] = useState([]);

//   const token = localStorage.getItem("token");
//   let userRole = "";
//   if (token) {
//     try {
//       const decodedToken = jwtDecode(token);
//       userRole = decodedToken.userRole;
//     } catch (error) {
//       console.error("Error decoding token: ", error);
//     }
//   }

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     fetch(`${process.env.REACT_APP_API_URL}/QuestionAnswers`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setAnswers(data);
//       })
//       .catch((error) => {
//         console.error("Error fetching answers:", error);
//       });
//   }, [token]);

//   return (
//     <div className="container" style={{ paddingTop: "150px" }}>
//       <div className="container">
//         <div className="row">
//           <div className="col-md-12 p-5">
//             <ul className="question-list">
//               {answers.map((answer) => (
//                 <li key={answer._id}>
//                   <div className="question-card">
//                     <div className="card-banner"></div>
//                     <div className="card-content">
//                       <h3 className="h3">
//                         <div href="#" className="card-title" >
//                         <p dangerouslySetInnerHTML={{ __html: answer.questionId.content }} />

//                         </div>
//                       </h3>
//                       <p className="card-text">
//                         <strong>Username: {answer.username}</strong>
//                       </p>
//                       <p className="card-text">{answer.content}</p>
//                       <a href="#" className="btn-link">
//                         <span className="span">Give Grade</span>
//                       </a>
//                     </div>
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Answers;
