import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import HeaderWrapper from "./HeaderWrapper";
function Main() {
  const [isDark, setIsDark] = useState(() => {
    const storedIsDark = localStorage.getItem("isDark");
    return storedIsDark !== null ? storedIsDark === "true" : false;
  });

  useEffect(() => {
    document.body.className = isDark ? "dark-mode" : "";
    localStorage.setItem("isDark", isDark);
    return () => {
      document.body.classList.remove("dark-mode");
    };
  }, [isDark]);

  const toggleDarkMode = () => {
    setIsDark(!isDark);
  };

  return (
    <div>
      <BrowserRouter>
        <HeaderWrapper isDark={isDark} toggleDark={toggleDarkMode} />

        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

export default Main;
