import React from "react";
import Main from "./components/pages/mainPages/Main";
import AppRoutes from "./components/pages/AppRoutes";

import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return <Main />;
}

export default App;
