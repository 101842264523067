import React from "react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function Dashboard() {
  const token = localStorage.getItem("token");
  let userRole = "";
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.userRole;
    } catch (error) {
      console.error("Error decoding token: ", error);
    }
  }

  return (
    <div>
      <div className="Dashboard">
        <div className="ccards" style={{ paddingTop: "200px" }}>
          <div className="containeer">
            <div className="row">
              {userRole === "admin" && (
                <div className="col-6">
                  <Link to="/Dashboard/Users">
                    <div className="cards">
                      <h1>Users</h1>
                    </div>
                  </Link>
                </div>
              )}

              {userRole === "admin" && (
                <div className="col-6">
                  <Link to="/Dashboard/RequestedUsers">
                    <div className="cards">
                      <h1>Requestd Users</h1>
                    </div>
                  </Link>
                </div>
              )}

              <div className="col-6">
                <Link to="/Questions">
                  <div className="cards">
                    <h1>Questions</h1>
                  </div>
                </Link>
              </div>

              {userRole === "admin" && (
                <div className="col-6">
                  <Link to="/Admin/NewAnswers">
                    <div className="cards">
                      <h1>Answers</h1>
                    </div>
                  </Link>
                </div>
              )}

              <div className="col-6">
                <div className="cards">
                  <h1>Score Board</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
