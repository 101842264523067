import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import style from "../css/NewAnswers.module.css";
import { jwtDecode } from "jwt-decode";

function NewAnswers() {
  const [answeredQuestions, setAnsweredQuestions] = useState([]);

  const token = localStorage.getItem("token");
  let userRole = "";
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.userRole;
    } catch (error) {
      console.error("Error decoding token: ", error);
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/questionsWithoutAnswers`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAnsweredQuestions(data);
      })
      .catch((error) => {
        console.error("Error fetching answered questions:", error);
      });
  }, []);

  return (
    <div className={style.NewAnswers} style={{ paddingTop: 150 }}>
      <h2 className="h2">Questions</h2>
      <div className={style.listCards}>
        {answeredQuestions.map((question) => (
            <div
              key={question._id}
              className={`${style.card} ${style.rounded} ${style.shadowed}`}
            >
          <Link to={`/Admin/Answers/${question._id}`} key={question._id}>
              <div className={style.cardContent}>
                <div
                  className={style.cardTitle}
                  dangerouslySetInnerHTML={{ __html: question.content }}
                />
              </div>
          </Link>
            </div>
        ))}
      </div>
    </div>
  );
}

export default NewAnswers;
