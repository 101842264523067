import React, { useState, useEffect } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    mail: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // emailSend(formData);
    setFormData({ username: "", phone: "", mail: "" });
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container" id="contact">
      <div className="form">
        <h2 className="h2 section-title">Get in Touch</h2>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="username"
            placeholder="name"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            id="phone"
            placeholder="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            id="mail"
            placeholder="email"
            name="mail"
            value={formData.mail}
            onChange={handleChange}
            required
          />
          <button type="submit" className="btn">
            Submit
          </button>
        </form>
      </div>
      <footer className="footer">
        <div className="footer-bottom">
          <div className="container">
            <p className="copyright">
              &copy; 2023 <span className="span">UTAS</span>. All rights
              reserved by
              <a href="#" className="copyright-link">
                ProgrammingClub
              </a>
            </p>

            <ul className="footer-bottom-list">
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdPqTMa-yi-peWD_btoOkHFGCFzJye6KciBxarbNYCzrKyWVw/viewform"
                  target="_blank"
                  className="footer-bottom-link"
                >
                  Join Our Club
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <a
        href="#top"
        className="back-top-btn"
        onClick={scrollToTop}
        aria-label="Back to top"
        data-back-top-btn
      >
        <ion-icon name="chevron-up"></ion-icon>
      </a>
    </div>
  );
}

export default Contact;
