import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../login/MainUser.css";
import signin from "../../../../assets/images/signup-image.jpg";
import g173 from "../../../../assets/images/g173.png";
import swal from "sweetalert";

function UserRegister() {
  const [formData, setFormData] = useState({
    name: "",
    utasId: "",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.repeatPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setPasswordError("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/UserRegister`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            utasId: formData.utasId,
            email: formData.email,
            password: formData.password,
          }),
        }
      );

      const text = await response.text();
      try {
        const result = JSON.parse(text);
        if (!response.ok) {
          throw new Error(result.message || "Registration failed");
        }
        swal("Success", "User registered successfully", "success");
        console.log(result);
      } catch (jsonError) {
        swal(
          "Error",
          `You Have Entered an Exsisted username or Email, Try Using Different Username Email: ${text}`,
          "error"
        );
      }
    } catch (error) {
      swal("Error", error.message, "error");
      console.error("Error:", error.message);
    }
  };

  return (
    <div className="bodylogin" style={{ paddingTop: "100px" }}>
      <section className="signup">
        <div className="containner">
          <div className="logo">
            <figure>
              <img
                src={g173}
                alt="Logo"
                className="imagelo"
                style={{ width: "250px", height: "150px", paddingTop: "50px" }}
              />
            </figure>
          </div>
          <div className="signup-content1">
            <div className="signup-form">
              <h2 className="form-title">Sign up</h2>
              <form
                method="POST"
                className="register-form"
                id="register-form"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="utasId"
                    id="cid"
                    placeholder="UTAS ID"
                    value={formData.utasId}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="repeatPassword"
                    id="re_pass"
                    placeholder="Repeat password"
                    value={formData.repeatPassword}
                    onChange={handleChange}
                  />
                </div>
                {passwordError && (
                  <p style={{ color: "red" }}>{passwordError}</p>
                )}
                {/* <div className="form-group form-button">
                  <input
                    type="submit"
                    value="Sign Up"
                    className="btn btn-primary"
                  />
                </div> */}
                <div className="form-group form-button">
                  <button type="submit" className="btn btn-primary">
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
            <div className="signup-image">
              <figure>
                <img src={signin} alt="sign up" className="imgsup" />
              </figure>
              <Link to="/User/login" className="signup-image-link">
                I am already member
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserRegister;
