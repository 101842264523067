import React, { useState, useRef, useEffect } from "react";

function Editor({ answerContent, setAnswerContent }) {
  const [code, setCode] = useState("// Write Your Code Here");

  const editorRef = useRef(null);

  const handlePaste = (e) => {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text/plain");

    const lines = pastedText.split("\n");

    const cleanedLines = lines.map((line) => line.trim());

    const cleanedText = cleanedLines.join("\n");

    document.execCommand("insertText", false, cleanedText);
  };

  useEffect(() => {
    if (answerContent !== code) {
      setCode(answerContent);
    }
  }, [answerContent]);

  const handleEditorChange = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.innerText;
      setCode(newContent);
      setAnswerContent(newContent);
    }
  };

  return (
    <figure className="app-banner-question">
      <div className="window">
        <header className="headclass">
          <ul className="cm-icons">
            <li title="close"></li>
            <li title="Restore Down"></li>
            <li title="Minimize"></li>
          </ul>
        </header>
        <div
          className="full-Question-editor"
          contentEditable="true"
          ref={editorRef}
          onPaste={handlePaste}
          onInput={handleEditorChange}
        >
          <pre>
            <code className="code-Editor" id="codeContent">
              {code}
            </code>
          </pre>
        </div>
      </div>
    </figure>
  );
}

export default Editor;

 