import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./mainPages/Home";
import About from "./mainPages/About";
import Services from "./mainPages/Services";
import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "./mainPages/dashboard/Dashboard";
import AllUsers from "./mainPages/dashboard/AllUsers";
import RequestedUsers from "./mainPages/dashboard/RequestedUsers";

import LoginPage from "./logins/admin/login/MainAdmin";
import Registeration from "./logins/admin/signup/Registeration";
import Question from "./mainPages/dashboard/QuestionPages/Question";
import FullQuestion from "./mainPages/dashboard/QuestionPages/FullQuestion";
import NewAnswers from "./mainPages/dashboard/QuestionPages/NewAnswers";

import UserRegister from "./logins/user/signup/UserRegister";
import MainUser from "./logins/user/login/MainUser";
import Answers from "./mainPages/dashboard/QuestionPages/Answers";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Admin */}
      <Route path="/Admin/login" element={<LoginPage />} />
      <Route path="/Admin/Registeration" element={<Registeration />} />
      <Route
        path="/Admin/Answers/:id"
        element={
          <ProtectedRoute requiredRole="admin">
            <Answers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Admin/NewAnswers"
        element={
          <ProtectedRoute requiredRole="admin">
            <NewAnswers />
          </ProtectedRoute>
        }
      />

      {/* End of Admin */}
      {/* User */}
      <Route path="/User/Registeration" element={<UserRegister />} />
      <Route path="/User/login" element={<MainUser />} />
      {/* End of User */}
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Services" element={<Services />} />

      <Route
        path="/Dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Dashboard/Users"
        element={
          <ProtectedRoute requiredRole="admin">
            <AllUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Dashboard/RequestedUsers"
        element={
          <ProtectedRoute requiredRole="admin">
            <RequestedUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Questions"
        element={
          <ProtectedRoute>
            <Question placeholder="Enter your Question" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/question/:questionId"
        element={
          <ProtectedRoute>
            <FullQuestion />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
