import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Home, LogOut } from "react-ionicons";
import user from "../../../assets/images/user.png";

function Navs({ isDark, toggleDark }) {
  const [open, setOpen] = useState(false);
  let menuRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("username");

    window.location.href = "/User/login";
  };

  const username = localStorage.getItem("username");
  const userRole = localStorage.getItem("userRole");

  return (
    <header
      className={`header active nav-active
      ${isDark ? "dark-mode" : ""}`}
    >
      <div className="container">
        <div className="menu-container" ref={menuRef}>
          <div className="menu-trigger" onClick={() => setOpen(!open)}>
            <img src={user} alt="User"></img>
          </div>

          <div className={`dropdown-menus ${open ? "active" : "inactive"}`}>
            <h3>
              {username}
              <br />
              <span style={{ paddingTop: 10 }}>{userRole}</span>
            </h3>
            <ul>
              <Link to="/Dashboard">
                <DropdownItems icon={<Home />} text="Home" />
              </Link>
              <DropdownItems icon={<LogOut />} text="Logout" onClick={logout} />
            </ul>
          </div>
        </div>
        <div className="navbar-item" style={{ display: "fixe d" }}>
          <div className="toggle-switch">
            <label className="switch-label">
              <input
                type="checkbox"
                className="checkbox"
                checked={isDark}
                onChange={toggleDark}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>
    </header>
  );
}

function DropdownItems({ icon, text, onClick }) {
  return (
    <li
      className="dropdownItems"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      {icon}
      <p>{text}</p>
    </li>
  );
}

export default Navs;
