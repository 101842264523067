import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";
import { MenuOutline } from "react-ionicons";
import { CloseOutline } from "react-ionicons";

function Header({ isDark, toggleDark }) {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleNavbar = () => {
    setIsNavActive(!isNavActive);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY >= 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <header
      className={`header ${isSticky ? "active" : ""} ${
        isNavActive ? "nav-active" : ""
      } ${isDark ? "dark-mode" : ""}`}
    >
      <div className="container">
        <h1>
          <Link to="/" className="logo" style={{ fontSize: "20px" }}>
            Programming Club
          </Link>
        </h1>

        <nav
          className={`navbar container ${isNavActive ? "active" : ""} ${
            isDark ? "dark-mode" : ""
          }`}
          data-navbar
        >
          <ul className="navbar-list">
            <li className="navbar-item">
              <Link to="/" className="navbar-link" onClick={toggleNavbar}>
                Home
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/" className="navbar-link" onClick={toggleNavbar}>
                About
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/" className="navbar-link" onClick={toggleNavbar}>
                Services
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/" className="navbar-link" onClick={toggleNavbar}>
                Contact Us
              </Link>
            </li>
            <li class="navbar-item">
              <span
                class="navbar-link"
                id="login-link"
                onClick={toggleDropdown}
                style={{ cursor: "pointer" }}
              >
                Login &#9662;
              </span>
              <ul
                class="dropdownList"
                className={`dropdown-menu ${
                  isDropdownVisible ? "dropdown-visible" : ""
                }`}
                id="login-dropdown"
              >
                <li>
                  <Link
                    to="/User/login"
                    style={{ display: isDropdownVisible ? "block" : "none" }}
                  >
                    Student
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Admin/login"
                    style={{ display: isDropdownVisible ? "block" : "none" }}
                  >
                    Admin
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div className="navbar-item">
          <div className="toggle-switch">
            <label className="switch-label">
              <input
                type="checkbox"
                className="checkbox"
                checked={isDark}
                onChange={toggleDark}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <button
          className="nav-toggle-btn"
          onClick={toggleNavbar}
          aria-label="Toggle menu"
        >
          {isNavActive ? (
            <CloseOutline className="menu-icon" width="50px" height="50px" />
          ) : (
            <MenuOutline className="menu-icon" width="50px" height="50px" />
          )}
        </button>
      </div>
    </header>
  );
}

export default Header;
