import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";

function QuestionList({ questions }) {
  const [questionState, setQuestionState] = useState(true);
  const [questionsList, setQuestionsList] = useState(questions);

  const token = localStorage.getItem("token");
  let userRole = "";
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userRole = decodedToken.userRole;
    } catch (error) {
      console.error("Error decoding token: ", error);
    }
  }

  const handleDelete = (questionId) => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_API_URL}/deleteQuestion/${questionId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Question deleted successfully:", data);
        swal("Success!", data.message, "success");

        setQuestionsList((prevQuestions) =>
          prevQuestions.filter((question) => question._id !== questionId)
        );
      })
      .catch((error) => {
        console.error("Error deleting question:", error);
      });
  };

  const toggleQuestionState = (questionId, newStatus) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("X-Question-State", newStatus);

    fetch(
      `${process.env.REACT_APP_API_URL}/toggleQuestionState/${questionId}`,
      {
        method: "PUT",
        headers: headers,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const { isActive } = data;

        setQuestionsList((prevQuestions) =>
          prevQuestions.map((question) =>
            question._id === questionId ? { ...question, isActive } : question
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling question state:", error);
      });
  };

  useEffect(() => {
    setQuestionsList(questions);
  }, [questions]);

  return (
    <div className="question-list">
      <div id="qa">
        <div className="w-70">
          <div id="feed">
            {questionsList.map((question, index) => (
              <div className="question-card card" key={question._id}>
                <div className="question-title cf w-100 valign-child">
                  <div className="fl">
                    <h2>
                      <Link
                        title="see full question"
                        to={`/question/${question._id}?questionNumber=${
                          index + 1
                        }&questionId=${question._id}`}
                      >
                        <mark className="mark">Question {index + 1}</mark>
                      </Link>
                    </h2>
                  </div>
                  {userRole === "admin" && (
                    <div className="fr mla">
                      <div className="editing-bar">
                        <button className="toggle-menu-option">⚙️</button>
                        <div className="menuOption">
                          <ul>
                            <li onClick={() => handleDelete(question._id)}>
                              Delete ❌
                            </li>
                            <li
                              onClick={() =>
                                toggleQuestionState(
                                  question._id,
                                  question.isActive ? "Retired" : "Active"
                                )
                              }
                            >
                              {question.isActive ? "Retire 🔴" : "Active 🟢"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="question-content">
                  <p dangerouslySetInnerHTML={{ __html: question.content }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionList;
