import React from "react";
import { services } from "./assets.js";
function Services() {
  return (
    <section className="section about" id="services">
      <div className="container">
        <h2 className="h2 section-title">What We Do !</h2>
        <p className="section-text">
          we foster awareness and enthusiasm for coding, encourage collaboration
          with tech entities, and promote innovation in programming projects. We
          also offer vital technical and academic support.
        </p>

        <ul className="about-list">
          {services.map((service, index) => (
            <li key={service.id}>
              <div className={`about-card about-card-${index + 1}`}>
                <figure className="card-banner">
                  <img
                    src={service.image}
                    width="94"
                    height="94"
                    loading="lazy"
                    alt={service.title}
                  />
                </figure>

                <div className="card-content">
                  <h3 className="h3">
                    <a href="#" className="card-title">
                      {service.description}
                    </a>
                  </h3>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Services;
