import React, { useState } from "react";
import "./MainUser.css";
import signin from "../../../../assets/images/signin-image.jpg";
import g173 from "../../../../assets/images/g173.png";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function MainUser() {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = loginData;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/UserLogin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Login failed");
      }

      const data = await response.json();

      localStorage.setItem("token", data.token);

      localStorage.setItem("username", data.user.name);

      navigate("/Dashboard");
      swal("Success!", data.message, "success");
      localStorage.setItem("isLoggedIn", "true");
    } catch (error) {
      console.error("Login Error:", error.message);
      swal("Error!", error.message, "error");
    }
  };

  return (
    <div className="bodylogin" style={{ paddingTop: "200px" }}>
      <section className="sign-in">
        <div className="containner">
          <div className="logo">
            <figure>
              <img
                src={g173}
                alt="Logo"
                className="imagelo"
                style={{
                  height: "120px",
                  paddingTop: 20,
                }}
              />
            </figure>
          </div>
          <div className="signin-content">
            <div className="signin-image">
              <figure>
                <img src={signin} alt="sign up" className="imagelo" />
              </figure>
            </div>

            <div className="signin-form">
              <h2 className="form-title">Sign In</h2>
              <form
                onSubmit={handleSubmit}
                className="register-form"
                id="login-form"
              >
                <div className="form-group">
                  <label htmlFor="your_name">
                    <i className="zmdi zmdi-account material-icons-name"></i>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="your_name"
                    placeholder="Email"
                    value={loginData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">
                    <i className="zmdi zmdi-lock"></i>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={loginData.password}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group form-button bt">
                  <button type="submit" className="btn btn-primary">
                    Sign In
                  </button>
                </div>
              </form>
              <div className="social-login">
                <span className="social-label">
                  <Link to="/User/Registeration" className="signup-image-link">
                    Create an account
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MainUser;
